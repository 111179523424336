import { useUserStore } from '~/stores/user';
import { useModalStore } from '~/stores/modal';
import { useMediaStore } from '~/stores/media';
import clone from 'clone';
import startCase from 'lodash/startCase';

export const useAgentStore = defineStore('agent', () => {
    const { 
      public: { 
        gcp: {
          storage: {
            endpoint: gcpEndpoint,
          }
        }, 
      }
    } = useRuntimeConfig();
    const toast = useToast()
    const route = useRoute()
    const { $i18n, $supabaseClient, $toast } = useNuxtApp();
    const userStore = useUserStore();
    const modalStore = useModalStore();
    const mediaStore = useMediaStore();
    
    const allAgent = ref<Agent[]>([]);
    const agent = ref<Agent>({});
    const editingAgent = reactive<Agent>({});

    //* sources
    const sources = ref<AgentSource[]>([]);
    const originalSources = ref<AgentSource[]>([]);
    const trainingSourceIds = ref<string[]>([]);
    const trainedSourceIds = ref<string[]>([]);
    const failedSourceIds = ref<string[]>([]);
    const deletedSources = ref<AgentSource[]>([]);
    
    //* training
    const isAgentTrained = ref(false);
    const isTrainingInProgress = ref(false);
    const planTrainingCharacterLimit = ref(0);

    const modelOptions = ref<Model[]>([]);
    const isShowSourceModal = ref(false);
    const sourceModalStep = ref(1);
    const editingSource = ref<AgentSource>({
        id: '',
        name: '',
        question: '',
        answer: '',
        type: '',
        displayType: '',
        content: '',
        url: '',
        created_at: '',
        file_path: '',
        character_count: 0,
    })

    const agentIdNamePairs = computed(() => {
        return allAgent.value.reduce((acc, agent) => {
            acc[agent.id] = agent.name;
            return acc;
        }, {} as { [key: string]: string });
    })

    const sourceWithStatus = computed(() => {
        const sourceIds = (sources.value ?? []).map((source) => source.id);
        const originalSourceIds = (originalSources.value ?? []).map((source) => source.id);
        const allSourceIds = new Set([...originalSourceIds, ...sourceIds])
        return [...allSourceIds].map((id: string) => {
            let status; // new, updated, trained, failed, untrained, deleted
            const currentSource = sources.value.find((source) => source.id === id);
            const originalSource = originalSources.value.find((source) => source.id === id);
            const source = currentSource ?? originalSource;
            
            if (trainingSourceIds.value.includes(id)) {
                status = 'training';
            }

            // if not existed in originalSourceIds, status = 'new'
            else if (!originalSourceIds.includes(id)) {
                status = 'new';
            }
            // if existed in originalSourceIds but not exists in sources.value, status = 'deleted'
            else if (!sourceIds.includes(id)) {
                status = 'deleted';
            }

            else if (failedSourceIds.value.includes(id)) {
                status = 'failed';
            }

            // if exists in originalSourceIds and sources.value
            else {
                let isUpdated = false;
                switch (currentSource?.type) {
                    case "website":
                        isUpdated = currentSource?.url !== originalSource?.url;
                        break;
                    case "file":
                        isUpdated = currentSource?.file_path !== originalSource?.file_path;
                        break;
                    case "q&a":
                        isUpdated = currentSource?.question !== originalSource?.question || currentSource?.answer !== originalSource?.answer;
                        break;
                    case "text":
                        isUpdated = currentSource?.content !== originalSource?.content || currentSource?.name !== originalSource?.name;
                        break;
                }

                if (isUpdated) {
                    status = "updated";
                } else {
                    status = trainedSourceIds.value.includes(id)
                        ? "trained"
                        : "untrained"
                }
            }

            return {
                ...source,
                status,
                displayType: source?.type === 'q&a' ? 'Q&A' : startCase(source?.type),
                displayName: source?.name,
                ...(source?.type === "website" && {
                    displayName: source.url,
                    href: source.url,
                }),
                ...(source?.type === "q&a" && {
                    displayName: source.question,
                }),
                ...(source?.type === "file" && {
                    href: `${gcpEndpoint}/${source.file_path}`,
                })
            }
        }).sort((a, b) => {
            return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
        })
    })  

    const trainingTotalCharacters = computed(() => {
        return sources.value.reduce((acc, curr) => acc + curr.character_count, 0);
    })

    const isTotalTrainingCharacterLimitReached = computed(() => {
        if (planTrainingCharacterLimit.value === 0) return false;
        return trainingTotalCharacters.value > planTrainingCharacterLimit.value;
    })

    const hasUnsavedSources = computed(() => {
        return sourceWithStatus.value.some((source) => ['new', 'updated', 'deleted'].includes(source.status));
    })

    const canTrainAgent = computed(() => {
        const hasSourcesToTrain = sourceWithStatus.value.some((source) => source.status !== 'trained');
        return !isTotalTrainingCharacterLimitReached.value
            && !isTrainingInProgress.value
            && hasSourcesToTrain
            && sources.value.length > 0;
    })

    const isConfigurationHasChanges = computed(() => {
        return (agent.value?.name ?? '').trim() !== (editingAgent?.name ?? '').trim()
            || (agent.value?.display_name ?? '').trim() !== (editingAgent?.display_name ?? '').trim()
            || (agent.value?.should_make_introduction ?? false) !== (editingAgent?.should_make_introduction ?? false)
            || (agent.value?.prompt ?? '').trim() !== (editingAgent?.prompt ?? '').trim()
            || (agent.value?.model ?? '').trim() !== (editingAgent?.model ?? '').trim()
            || (agent.value?.additional_instructions ?? '').trim() !== (editingAgent?.additional_instructions ?? '').trim()
    })

    async function fetchFirstAgent() {        
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                id
            `)
            .eq('workspace_id', userStore.user?.workspaceId)
            .limit(1);
        if (error) throw error;
        allAgent.value = data;
    }

    async function fetchAllAgent() {
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                id,
                name,
                created_at,
                agent_trainings (
                    created_at
                )
            `)
            .eq('workspace_id', userStore.user?.workspaceId)
            .eq('agent_trainings.status', 'completed')
            .order('created_at', {
                ascending: false,
                referencedTable: 'agent_trainings',
            })
            .limit(1, {
                referencedTable: 'agent_trainings',
            });
        if (error) throw error;
        allAgent.value = data;
    }

    async function fetchAgent() {
        const { data, error } = await $supabaseClient
            .from('agents')
            .select(`
                *,
                agent_sources (
                    *,
                    agent_embeddings (count)
                ),
                agent_trainings (
                    status,
                    created_at,
                    status_updated_at,
                    agent_training_source (
                        agent_source_id,
                        status
                    )
                )
            `)
            .eq('id', route.params.id)
            .order('created_at', {
                referencedTable: "agent_sources",
                ascending: false,
            })
            .order("created_at", {
                referencedTable: "agent_trainings",
                ascending: false,
            })
            .limit(1, {
                referencedTable: 'agent_trainings',
            })
            .maybeSingle();

        if (error) throw error;
        if (!data) return;

        const {
            status,
            created_at,
            status_updated_at,
            agent_training_source = [],
        } = data?.agent_trainings?.[0] ?? {};
        agent.value = {
            ...data,
            status,
            status_updated_at,
            training_started_at: created_at,
        };
        Object.assign(editingAgent, data);
        sources.value = data?.agent_sources || [];
        originalSources.value = clone(sources.value);
        isAgentTrained.value = data?.agent_trainings?.length > 0;
        isTrainingInProgress.value = status === "in_progress";
        trainedSourceIds.value = (data?.agent_sources ?? []).filter((source) => source.agent_embeddings?.[0]?.count > 0).map((source) => source.id) || [];
        trainingSourceIds.value = agent_training_source.filter((source) => ['in_progress', 'queued'].includes(source.status)).map((source) => source.agent_source_id) || [];
        failedSourceIds.value = agent_training_source.filter((source) => source.status === "failed").map((source) => source.agent_source_id) || [];
    }

    async function fetchAgentModels() {
        const { data, error } = await $supabaseClient
            .from('ai_models')
            .select(`
                label:name,
                value:slug,
                is_premium,
                description
            `)
            .eq('provider', 'openai');
        if (error) throw error;
        modelOptions.value = data.map((model) => ({
            ...model,
            disabled: model.is_premium && userStore.user?.isInFreePlan,
        }));
    }

    async function upsertSource(source: AgentSource) {
        let filePath = '';
        if (source.type === 'file') {
            const storedFile = await mediaStore.uploadFile({
                file: source.file,
                folder: agent.value?.id,
                category: "agent-sources",
            }); 
            filePath = storedFile.path;
        }

        const { error } = await $supabaseClient
            .from('agent_sources')
            .upsert({
                ...(source.id ? { id: source.id } : {}),
                ...(source.type === "website" && {
                    url: source.url,
                }),
                ...(source.type === "file" && {
                    name: source.name,
                    file_path: filePath,
                }),
                ...(source.type === "q&a" && {
                    name: null,
                    content: null,
                    question: source.question,
                    answer: source.answer,
                }),
                ...(source.type === "text" && {
                    name: source.name,
                    content: source.content,
                    question: null,
                    answer: null,
                }),
                agent_id: agent.value.id,
                type: source?.type || null,
                character_count: source?.character_count || 0,
            });
        if (error) throw error;
    }

    async function deleteSource(id: string) {
        try {
            await $fetch('/api/agent/source', {
                method: 'DELETE',
                query: {
                    id
                }
            });
            sources.value = sources.value.filter((source) => source.id !== id);
        } catch (error) {
            $toast.error('Failed to delete source');
            throw error;
        }
    }

    async function saveSource() {
        const sourcesToUpsert = sourceWithStatus.value.filter((source) => source.status === "new" || source.status === "updated");
        const sourcesToDelete = sourceWithStatus.value.filter((source) => source.status === "deleted");
        // console.log({
        //     sourcesToUpsert,
        //     sourcesToDelete,
        // })

        await Promise.all([
            ...sourcesToUpsert.map(source => upsertSource(source)),
            ...sourcesToDelete.map(source => deleteSource(source.id)),
        ])
    }

    async function updateAgent({
        name,
        display_name,
        should_make_introduction,
        prompt,
        model,
        additional_instructions,
    }: Agent) {
        const { error } = await $supabaseClient
            .from('agents')
            .update({
                name,
                display_name,
                should_make_introduction,
                prompt,
                model,
                additional_instructions,
            })
            .eq('id', agent.value.id);
        if (error) throw error;
        agent.value = {
            ...agent.value,
            name,
            display_name,
            should_make_introduction,
            prompt,
            model,
            additional_instructions,
        }
    }

    async function trainAgent() {
        isTrainingInProgress.value = true;
        try {
            trainingSourceIds.value = sourceWithStatus.value
                .filter((source) => ['new', 'updated', 'failed', 'untrained'].includes(source.status))
                .map((source) => source.id);
            const { error } = await $supabaseClient.functions.invoke('agent-training-trigger', {
                body: {
                    agentId: agent.value.id,
                }
            })

            if (error) throw error;

            toast.add({
                id: 'training_in_progress',
                title: 'Agent training in progress.',
                description: 'We will notify you when the training is completed. Feel free to navigate away from this page.',
                icon: 'i-lucide-brain-circuit',
                ui: {
                    icon: {
                        base: "mt-1",
                    }
                }
            })
        } catch (error) {
            $toast.error('Failed to train agent. Please try again later or contact support.');
            throw error;
        }
    }

    function toggleSourceModal() {
        isShowSourceModal.value = !isShowSourceModal.value;
        sourceModalStep.value = 1;
    }

    function triggerDeleteConfirmationModal(id: string, name: string) {
        modalStore.open(id, 'agent source', name);
    }

    return {
        agent,
        allAgent,
        editingAgent,
        isAgentTrained,
        modelOptions,
        isShowSourceModal,
        sourceModalStep,
        editingSource,
        agentIdNamePairs,
        //* sources
        sources,
        sourceWithStatus,
        trainingSourceIds,
        failedSourceIds,
        deletedSources,
        hasUnsavedSources,
        //* training
        canTrainAgent,
        isTrainingInProgress,
        trainedSourceIds,
        planTrainingCharacterLimit,
        isTotalTrainingCharacterLimitReached,
        trainingTotalCharacters,
        isConfigurationHasChanges,
        fetchAgent,
        fetchFirstAgent,
        fetchAllAgent,
        fetchAgentModels,
        toggleSourceModal,
        saveSource,
        updateAgent,
        trainAgent,
        triggerDeleteConfirmationModal,
    };
});

export interface Agent {
    id: string;
    name: string;
    model: string;
    prompt: string;
    display_name: string;
    should_make_introduction: boolean;
    created_at: string;
    status: string;
    training_started_at: string;
    status_updated_at: string;
    additional_instructions: string | null;
}

export interface AgentSource {
    id?: string;
    name?: string;
    type: string;
    displayType?: string;
    content?: string;
    question?: string;
    answer?: string;
    file_path?: string;
    character_count: number;
    url?: string;
    created_at?: string;
}

interface Model {
    label: string;
    value: string;
    is_premium: boolean;
    description: string;
}

export const sourceTypeLabels = {
	"q&a": "Q&A",
	"website": "Website",
	"file": "File",
	"text": "Text snippet",
}